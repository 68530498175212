import guri_i_kuq_0 from '../assets/images/gallery/guri_i_kuq/0.jpg'
import guri_i_kuq_1 from '../assets/images/gallery/guri_i_kuq/1.jpg'
import guri_i_kuq_2 from '../assets/images/gallery/guri_i_kuq/2.jpg'
import guri_i_kuq_3 from '../assets/images/gallery/guri_i_kuq/3.jpg'
import guri_i_kuq_4 from '../assets/images/gallery/guri_i_kuq/4.jpg'

import stanet_1 from '../assets/images/gallery/stanet/1.jpg'
import stanet_2 from '../assets/images/gallery/stanet/3.jpg'
import stanet_3 from '../assets/images/gallery/stanet/4.jpg'
import marigo_1 from '../assets/images/gallery/marigo_j/1.jpg'
import marigo_2 from '../assets/images/gallery/marigo_j/2.jpg'
import marigo_3 from '../assets/images/gallery/marigo_j/3.jpg'
import natyra_1_b from '../assets/images/gallery/natyra_b/1.jpg'
import natyra_2_b from '../assets/images/gallery/natyra_b/2.jpg'
import natyra_3_b from '../assets/images/gallery/natyra_b/3.jpg'

import nur_1 from '../assets/images/gallery/nur_m/unnamed.jpg'
import nur_2 from '../assets/images/gallery/nur_m/img1.jpg'
import nur_3 from '../assets/images/gallery/nur_m/img3.jpg'
import nur_4 from '../assets/images/gallery/nur_m/img4.jpg'
import nur_5 from '../assets/images/gallery/nur_m/img5.jpg'
import nur_6 from '../assets/images/gallery/nur_m/img6.jpg'
import nur_7 from '../assets/images/gallery/nur_m/img7.jpg'
import nur_8 from '../assets/images/gallery/nur_m/img2.jpg'

import npishe_1 from '../assets/images/gallery/npisha_rugove/1.jpg'
import npishe_2 from '../assets/images/gallery/npisha_rugove/2.jpg'
import npishe_3 from '../assets/images/gallery/npisha_rugove/3.jpg'
import npishe_4 from '../assets/images/gallery/npisha_rugove/4.jpg'
import npishe_5 from '../assets/images/gallery/npisha_rugove/5.jpg'


import nshpat_1 from '../assets/images/gallery/nshpat/2.jpg'
import nshpat_2 from '../assets/images/gallery/nshpat/3.jpg'
import nshpat_3 from '../assets/images/gallery/nshpat/4.jpg'
import nshpat_4 from '../assets/images/gallery/nshpat/5.jpg'
import nshpat_5 from '../assets/images/gallery/nshpat/`.jpg'


import casa_r_1 from '../assets/images/gallery/casa_r/`.jpg'
import casa_r_2 from '../assets/images/gallery/casa_r/2.jpg'
import casa_r_3 from '../assets/images/gallery/casa_r/3.jpg'
import casa_r_4 from '../assets/images/gallery/casa_r/4.jpg'
import casa_r_5 from '../assets/images/gallery/adriatiku/1.jpg'


import adriatiku_1 from '../assets/images/gallery/adriatiku/1.jpg'
import adriatiku_2 from '../assets/images/gallery/adriatiku/2.jpg'
import adriatiku_3 from '../assets/images/gallery/adriatiku/3.jpg'
import adriatiku_4 from '../assets/images/gallery/adriatiku/4.jpg'
import adriatiku_5 from '../assets/images/gallery/adriatiku/5.jpg'


import kalaja_j_1 from '../assets/images/gallery/kalaja_j/1.jpg'
import kalaja_j_2 from '../assets/images/gallery/kalaja_j/2.jpg'
import kalaja_j_3 from '../assets/images/gallery/kalaja_j/3.jpg'
import kalaja_j_4 from '../assets/images/gallery/kalaja_j/4.jpg'
import kalaja_j_5 from '../assets/images/gallery/kalaja_j/5.jpg'


import roza_1 from '../assets/images/gallery/roza/1.jpg'
import roza_2 from '../assets/images/gallery/roza/2.jpg'
import roza_3 from '../assets/images/gallery/roza/3.jpg'
import roza_4 from '../assets/images/gallery/roza/4.jpg'
import roza_5 from '../assets/images/gallery/roza/5.jpg'


import oxygen_prevalle_1 from '../assets/images/gallery/oxygen_prevalle/0.jpg'
import oxygen_prevalle_2 from '../assets/images/gallery/oxygen_prevalle/1.jpg'
import oxygen_prevalle_3 from '../assets/images/gallery/oxygen_prevalle/2.jpg'

import riza_1 from '../assets/images/gallery/riza/thumb.jpg'
import riza_2 from '../assets/images/gallery/riza/foto1.jpg'
import riza_3 from '../assets/images/gallery/riza/foto2.jpg'
import riza_4 from '../assets/images/gallery/riza/foto3.jpg'

import teliqeni_1 from '../assets/images/gallery/teliqeni/10.png'
import teliqeni_2 from '../assets/images/gallery/teliqeni/11.png'
import teliqeni_3 from '../assets/images/gallery/teliqeni/13.png'
import teliqeni_4 from '../assets/images/gallery/teliqeni/15.png'
import teliqeni_5 from '../assets/images/gallery/teliqeni/222.jpg'
import teliqeni_6 from '../assets/images/gallery/teliqeni/333.jpg'
import teliqeni_7 from '../assets/images/gallery/teliqeni/367473559_673063334859069_1944184125940072291_n.jpg'
import teliqeni_8 from '../assets/images/gallery/teliqeni/7.png'
import teliqeni_9 from '../assets/images/gallery/teliqeni/8.png'
import teliqeni_10 from '../assets/images/gallery/teliqeni/9.png'
import teliqeni_11 from '../assets/images/gallery/teliqeni/12.png'







const data = [
  {id: 'te_liqeni',
    name: 'Te Liqeni',
    rooms: ['1', '2'], 
    people: ['1', '2', '3', '4', '5', '6','7','8','9'], 
    image: teliqeni_1, 
    gallery: [teliqeni_1,teliqeni_2, teliqeni_3, teliqeni_4, teliqeni_5, teliqeni_6, teliqeni_7, teliqeni_8, teliqeni_9, teliqeni_10, teliqeni_11],
    socialLinks: { insta: "https://www.instagram.com/te_liqeni_rugove/", facebook: "https://www.facebook.com/Restaurant.Liqeni/photos/", phoneNumber: "+38349828834", booking: "" },
    location: 'Rugovë',
    latLng: [42.683292568943095, 20.085764075668592],
  },
  {
    id: "villa_nur",
    name: 'Villa Nur',
    rooms: ['1', '2', '3'], 
    people: ['1', '2', '3', '4', '5', '6', '7', '8', '9'], 
    image: nur_2, 
    gallery: [nur_1, nur_2, nur_3, nur_4, nur_5, nur_6, nur_7, nur_8],
    socialLinks: {
      insta: "https://www.instagram.com/apartmani.mavrovi.anovi/",
      facebook: "", phoneNumber: "+38978345751", booking: "https://www.booking.com/hotel/mk/villa-nur-lake-view-apartaments-mavrovo-anovi.en-gb.html"
    },
    location: 'Mavrovë',
    latLng: [41.70196426348148, 20.756985358071493], 
  },
  {
    id: "villa_riza",
    name: 'Villa Riza',
    rooms: ['1'], 
    people: ['1', '2', '3'], 
    image: riza_1, 
    gallery: [riza_1, riza_2, riza_3, riza_4],
    socialLinks: { insta: "https://www.instagram.com/villarizagrashtice/", facebook: "https://www.facebook.com/villarizagrashtice", phoneNumber: "+38348883700", booking: "" },
    location: 'Grashticë',
    latLng: [42.69335154275393, 21.279656094765176], 
  },
  {
    id: "villat_npisha",
    name: 'Villat nPisha',
    rooms: ['2'], 
    people: ['1', '2', '3', '4', '5'], 
    image: npishe_1, 
    gallery: [npishe_1, npishe_2, npishe_3, npishe_4, npishe_5],
    socialLinks: { insta: "https://www.instagram.com/villat_npisharugove/", facebook: "https://www.facebook.com/villatnpisha", phoneNumber: "+38348636261", booking: "" },
    location: 'Rugovë',
    latLng: [42.692646039831075, 20.07390582976762], 
  },
  {
    id: "villat_oxygen",
    name: 'Villat Oxygen',
    rooms: ['2'], 
    people: ['1', '2', '3', '4', '5', '6'], 
    image: oxygen_prevalle_1, 
    gallery: [oxygen_prevalle_1, oxygen_prevalle_2, oxygen_prevalle_3],
    socialLinks: { insta: "https://www.instagram.com/villaoxygen/", facebook: "https://www.facebook.com/villa.oxygen.prevalle", phoneNumber: "+38344130580", booking: "" },
    location: 'Prevallë',
    latLng: [42.18041383211748, 20.978281814906033], 
  },
  {
    id: "guri_i_kuq",
    name: 'Guri i Kuq',
    rooms: ['1', '2'], 
    people: ['1', '2', '3', '4', '5', '6'], 
    image: guri_i_kuq_0, 
    gallery: [guri_i_kuq_0, guri_i_kuq_1, guri_i_kuq_2, guri_i_kuq_3, guri_i_kuq_4],
    socialLinks: { insta: "https://www.instagram.com/guriikuqrestaurant", facebook: "https://www.facebook.com/RestaurantGuriIKuqRugove", phoneNumber: "+38349150551", booking: "" },
    location: 'Rugovë',
    latLng: [42.69134533664285, 20.112038079569615], 
  },
  {
    id: "villat_nshpat",
    name: 'Villat nShpat',
    rooms: ['2', '3'], 
    people: ['1', '2', '3', '4', '5', '6'], 
    image: nshpat_1, 
    gallery: [nshpat_1, nshpat_2, nshpat_3, nshpat_4, nshpat_5],
    socialLinks: { insta: "https://www.instagram.com/villat.nshpat/", facebook: "https://www.facebook.com/profile.php?id=100047092166229", phoneNumber: "+38349826679", booking: "" },
    location: 'Rugovë',
    latLng: [42.689026543876025, 20.092682682595534], 
  },
  {
    id: "villat_marigo",
    name: 'Villa Marigo',
    rooms: ['2'], 
    people: ['1', '2', '3', '4', '5', '6'], 
    image: marigo_1, 
    gallery: [marigo_1, marigo_2, marigo_3],
    socialLinks: { insta: "https://www.instagram.com/villamarigo/", facebook: "https://www.facebook.com/Villamarigo2020", phoneNumber: "+38344936652", booking: "" },
    location: 'Jezercë',
    latLng: [42.37108894308116, 21.049825254944814], 
  },
  {
    id: "villat_natyra",
    name: 'Villat Natyra',
    rooms: ['2'], 
    people: ['1', '2', '3', '4', '5', '6', '7', '8'], 
    image: natyra_1_b, 
    gallery: [natyra_1_b, natyra_2_b, natyra_3_b],
    socialLinks: { insta: "https://www.instagram.com/villatnatyra/", facebook: "https://www.facebook.com/villatnatyra", phoneNumber: "+38349139419", booking: "" },
    location: 'Rugovë',
    latLng: [42.73969794330442, 20.05860304327046], 
  },
  {
    id: "villat_casa",
    name: 'Villat Casa',
    rooms: ['1', '2'], 
    people: ['1', '2', '3', '4', '5', '6'], 
    image: casa_r_1, 
    gallery: [casa_r_1, casa_r_2, casa_r_3, casa_r_4, casa_r_5],
    socialLinks: { insta: "https://www.instagram.com/villat_casa/", facebook: "", phoneNumber: "+38349667648", booking: "" },
    location: 'Rugovë',
    latLng: [], 
  },
  {
    id: "villat_adriatiku",
    name: 'Villat Adriatiku',
    rooms: ['1'], 
    people: ['1', '2', '3', '4', '5'], 
    image: adriatiku_1, 
    gallery: [adriatiku_1, adriatiku_2, adriatiku_3, adriatiku_4, adriatiku_5],
    socialLinks: { insta: "https://www.instagram.com/villatadriatiku/", facebook: "https://www.facebook.com/Villat.Adriatiku", phoneNumber: "+38346200800", booking: "" },
    location: 'Batllavë',
    latLng: [42.81546100105599, 21.35712916939967], 
  },
  {
    id: "kalaja",
    name: 'Villa Kalaja',
    rooms: ['1'], 
    people: ['1', '2', '3', '4', '5', '6', '7', '8'], 
    image: kalaja_j_1, 
    gallery: [kalaja_j_1, kalaja_j_2, kalaja_j_3, kalaja_j_4, kalaja_j_5],
    socialLinks: { insta: "https://www.instagram.com/villakalaja_jezerc/", facebook: "https://www.facebook.com/profile.php?id=100063138524499", phoneNumber: "+38344224250", booking: "" },
    location: 'Jezerc',
    latLng: [], 
  },
  {
    id: "villa_roza",
    name: 'Villa Roza',
    rooms: ['1'], 
    people: ['1', '2', '3', '4', '5'], 
    image: roza_1, 
    gallery: [roza_1, roza_2, roza_3, roza_4, roza_5],
    socialLinks: { insta: "https://www.instagram.com/villatrozaa/", facebook: "https://www.facebook.com/profile.php?id=100068831642222", phoneNumber: "+38349204206", booking: "" },
    location: 'Novobërd',
    latLng: [42.56213526274355, 21.43946585553549], 
  },
  {
    id: "bujtina_stanet",
    name: 'Bujtina Stanet',
    rooms: ['1', '2'], 
    people: ['1', '2', '3', '4', '5', '6', '7', '8'], 
    image: stanet_1, 
    gallery: [stanet_1, stanet_2, stanet_3],
    socialLinks: {
      insta: "https://www.instagram.com/bujtinat_malore_stanet/",
      facebook: "https://www.facebook.com/bujtinatmalore",
      phoneNumber: "+38345155545",
      booking: "",
    },
    location: 'Novobërd',
    latLng: [42.56181852443112, 21.43928703039411], 
  },


];


export default data